// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@roambee\\/client-hc20 {
	#main-layout {
		width: 100%;
		&.sidenav-normal {
			width: calc(100% - 240px);
			margin-left: 240px;
		}
		&.sidenav-small {
			width: calc(100% - 80px);
			margin-left: 80px;
		}
	}
	#header {
		position: fixed;
		width: inherit;
		height: 58px;
		z-index: 998;
		.header {
			.MuiAutocomplete-root {
				min-width: 12rem;
			}
		}
	}
}
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;CACC;EACC,WAAW;EACX;GACC,yBAAyB;GACzB,kBAAkB;EACnB;EACA;GACC,wBAAwB;GACxB,iBAAiB;EAClB;CACD;CACA;EACC,eAAe;EACf,cAAc;EACd,YAAY;EACZ,YAAY;EACZ;GACC;IACC,gBAAgB;GACjB;EACD;CACD;AACD","sourcesContent":["#single-spa-application\\:\\@roambee\\/client-hc20 {\n\t#main-layout {\n\t\twidth: 100%;\n\t\t&.sidenav-normal {\n\t\t\twidth: calc(100% - 240px);\n\t\t\tmargin-left: 240px;\n\t\t}\n\t\t&.sidenav-small {\n\t\t\twidth: calc(100% - 80px);\n\t\t\tmargin-left: 80px;\n\t\t}\n\t}\n\t#header {\n\t\tposition: fixed;\n\t\twidth: inherit;\n\t\theight: 58px;\n\t\tz-index: 998;\n\t\t.header {\n\t\t\t.MuiAutocomplete-root {\n\t\t\t\tmin-width: 12rem;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
