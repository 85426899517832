import { createElement, lazy } from "react";
import { Delivery } from "@carbon/icons-react";
import { RouteType } from ".";

const ShipmentTable = lazy(() => import("../modules/shipment/ShipmentTable"));

const shipmentRoutes: RouteType[] = [
	{
		path: "hc20/shipments",
		element: ShipmentTable,
		title: "Shipments",
		icon: createElement(Delivery),
		visible: true
	}
];

export default shipmentRoutes;
