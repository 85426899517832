/* eslint-disable @typescript-eslint/ban-ts-comment */
import "./App.scss";

// @ts-ignore
import { GlobalSearch, Loader } from "@roambee/client-styleguide";
// @ts-ignore
import { API, useAuthUser, setRoutes, EventEmitter } from "@roambee/client-utility";
// import routes from "./routes/Routes";
import { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { API_PATH } from "./configs/APIConfig";
import routes from "./routes";

function App() {
	const navigate = useNavigate();
	const { data: user, authenticated, loading } = useAuthUser();

	const [isWide, setWide] = useState(true);
	const [accounts, setAccounts] = useState([]);
	const [account, setAccount] = useState({});

	// Find the current route based on the path
	const currentRoute = routes.find((route) => `/${route.path}` === location.pathname);

	const menuItems = [
		{
			lable: "Logout",
			clickHandler: () => {
				// logout from server
				API("POST", API_PATH.LOGOUT, {}).then((res) => {
					if (res.post_logout_url) {
						window.location.href = res.post_logout_url + "?redirect_uri=" + encodeURIComponent(window.location.origin);
					} else {
						navigate("/auth");
					}
				});
			}
		}
	];

	setRoutes(routes);

	useEffect(() => {
		EventEmitter.addListener("toggleSideNav", (eventData) => {
			setWide(eventData);
		});

		return () => {
			EventEmitter.removeListener("toggleSideNav", (eventData) => {
				setWide(eventData);
			});
		};
	}, []);

	useEffect(() => {
		// Only navigate if we've finished loading and still not authenticated
		if (!loading) {
			if (!authenticated) {
				navigate("/auth");
				return;
			}

			if (authenticated && user) {
				API("GET", "/accounts?quick_view=true&all=true")
					.then((result) => {
						if (result.rows && result.rows.length) {
							setAccounts(result.rows);
						}
						if (user && user.account && user.account.data) {
							setAccount({ label: user.account.data?.title, id: user.account?.uuid });
						}
					})
					.catch((error) => {
						if (error.status === 404 && error.message) {
							// eslint-disable-next-line no-console
							console.error(error.message);
						} else {
							// eslint-disable-next-line no-console
							console.error("Error:", error);
							EventEmitter.emit("showSnackbar", {
								id: "error",
								leftIcon: true,
								message: error?.message || "Something went wrong!",
								variant: "error",
								ariaLabel: "close button",
								position: "top-right"
							});
						}
					});
			}
		}
	}, [authenticated, loading, user, navigate]);

	const handleSwitchAccount = async (event, newValue) => {
		const newCustomerDetails = {
			email: "",
			account_uuid: ""
		};

		accounts.forEach((account) => {
			if (account.uuid === newValue.id) {
				newCustomerDetails.email = account.users[0].email;
				newCustomerDetails.account_uuid = account.uuid;
			}
		});
		await API("POST", "/admin/delegate", newCustomerDetails).then(() => {
			window.location.reload();
		});
	};

	return (
		<section id="main-layout" className={isWide ? "sidenav-normal" : "sidenav-small"}>
			{
				authenticated && (<section id="header">
					<GlobalSearch
						className="header"
						user={user}
						showSearch={false}
						showNotification={false}
						title={currentRoute ? currentRoute.title : "Welcome to Roambee"}
						menuItems={menuItems}
						accounts={accounts.map((account) => {
							return { label: account.title, id: account.uuid };
						})}
						account={account}
						handleSwitchAccount={handleSwitchAccount}
					/>
				</section>)
			}
			{
				authenticated && (
					<section id="container-layout">
						<Suspense fallback={<Loader />}>
							<Routes>
								{routes.map((route, idx) => (
									<Route key={idx} path={route.path} element={<route.element />} />
								))}
							</Routes>
						</Suspense>
					</section>)
			}

		</section>
	);
}

export default App;
