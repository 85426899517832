import { createElement, lazy } from "react";
import { WifiController } from "@carbon/icons-react";
import { RouteType } from ".";

const BeeTable = lazy(() => import("../modules/bee/BeeTable"));

const beeRoutes: RouteType[] = [
	{
		path: "hc20/bees",
		element: BeeTable,
		title: "Bees",
		icon: createElement(WifiController),
		visible: true
	}
];

export default beeRoutes;
