import { createElement, lazy } from "react";
import { DeliveryParcel } from "@carbon/icons-react";
import { RouteType } from ".";

const AssetTable = lazy(() => import("../modules/asset/AsseTable"));

const assetRoutes: RouteType[] = [
	{
		path: "hc20/assets",
		element: AssetTable,
		title: "Assets",
		icon: createElement(DeliveryParcel),
		visible: true
	}
];

export default assetRoutes;
